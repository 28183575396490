<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <!-- <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow"> -->
    <h2 class="mt-4 text-center text-3xl font-bold text-gray-900">
      Order complete
    </h2>
  </div>
  <div class="mt-8 mx-auto w-full max-w-2xl">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div v-if="validateMembershipState.result && validateMembershipState.result.record">
        <div v-if="eventId === '122'">
          <p class="mt-2 font-bold">Thank you for your order to attend Prayer Storm Conference 2025.</p>
          <p class="mt-2">You will receive an email asking you to CONFIRM your ticket details. Please kindly confirm the details as requested.</p>
          <p class="mt-2"><em>If you do not receive the email in the next few hours, then please check your junk mail. Failing that, please contact <strong><span>tech</span>@prayerstorm.org</strong> for assistance.</em></p>

          <h2 class="mt-8 text-2xl font-bold text-gray-900">Support this conference</h2>
          <p class="mt-2">In order to keep ticket costs at a reasonable level, we ask that you consider supporting Prayer Storm Conference 2025. We aim to fund this amazing conference opportunity with your generous support and minimal ticket pricing to make if as affordable as possible for all to attend.</p>

          <div class="mt-4 w-full">
            <div class="flex justify-between pb-4">
              <a class="font-bold text-lg hover:underline" :href="'/support/4/checkout/' + validateMembershipState.sessionId">
                Prayer Storm Conference 2025 Support
              </a>
              <div class="flex-none pt-2">
                <a class="bg-blue hover:bg-blue-dark rounded-md px-6 py-3 text-md font-semibold text-white" :href="'/support/4/checkout/' + validateMembershipState.sessionId">
                  Donate Now
                </a>
              </div>
            </div>
            <div class="overflow-hidden rounded-full bg-gray-200 mt-2 lg:mt-4">
              <div class="h-2 rounded-full bg-blue" style="width: 20%" />
            </div>
            <div class="flex justify-between my-2 lg:my-4">
              <div class="mr-6">
                <div class="text-lg font-bold">£10,493</div>
                <div>Raised</div>
              </div>
              <div>
                <div class="text-lg font-bold">£50,000</div>
                <div class="text-right">Total Goal</div>
              </div>
            </div>
            <p class="mt-2"><em>** Periodically updates</em></p>
          </div>

        </div>
        <div v-else>
          <p>Thank you, your order was successful.</p>
          <p class="mt-2">If you ordered an <span class="font-bold">online membership or online eTicket</span>, this should now be active on your account.</p>
          <p class="mt-2">If you ordered <span class="font-bold">tickets to attend an in-person event</span>, then you will receive an email asking you to CONFIRM your ticket details. Please kindly confirm the details as requested. <em>If you do not receive the email in the next few hours, then please check your junk mail. Failing that, please contact tech@prayerstorm.org for assistance.</em></p>
          <router-link :to="redirectUrl" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
            Continue
          </router-link>
        </div>
      </div>
      <div v-else>
        <p>Validating. Please wait...</p>
        <ErrorMessage :formError="validateMembershipState.error"></ErrorMessage>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useMemberships } from '@/composables/useMemberships'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
  name: 'CheckoutSuccess',
  components: {
    ErrorMessage
  },
  setup () {
    const route = useRoute()
    const { validateMembershipState, validateMembership } = useMemberships()
    const eventId = ref(route.params.eventId)

    // set the sessionId
    validateMembershipState.sessionId = route.params.sessionId

    const redirectUrl = route.query.return ? route.query.return : '/'

    onMounted(() => {
      validateMembership()
        .then(() => {
        }).catch(() => {
        })
    })

    return {
      eventId,
      validateMembershipState,
      redirectUrl
    }
  }
})
</script>
