<template>
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6">
    <div class="flex justify-between">
      <h2 class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">Conference Support</h2>
    </div>
    <div class="mt-2 bg-white p-2 rounded-lg flex">
      <div class="w-1/3 hidden md:block">
        <img src="https://pslive.blob.core.windows.net/images/event/fa6ca0a0-a26e-11ef-a5c5-e5aae5f43355-event.jpg" class="w-full rounded-md" />
      </div>
      <div class="w-full md:w-3/4 md:pl-4 pt-2 lg:pt-4 lg:pr-4">
        <div class="flex justify-between pb-4">
          <a class="font-bold text-lg hover:underline" href="/support/4/prayer-storm-conference-2025">
            Prayer Storm Conference 2025 Support
          </a>
          <div class="flex-none pt-2">
            <a class="bg-blue hover:bg-blue-dark rounded-md px-6 py-3 text-md font-semibold text-white" href="/support/4/prayer-storm-conference-2025">
              Donate Now
            </a>
          </div>
        </div>
        <div class="overflow-hidden rounded-full bg-gray-200 mt-2 lg:mt-4">
          <div class="h-2 rounded-full bg-blue" style="width: 20%" />
        </div>
        <div class="flex justify-between my-2 lg:my-4">
          <div class="mr-6">
            <div class="text-lg font-bold">£10,493</div>
            <div>Raised</div>
          </div>
          <div>
            <div class="text-lg font-bold">£50,000</div>
            <div class="text-right">Total Goal</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
