<template>
<main>
  <LiveNow></LiveNow>
  <HomeFeatured :panels="contentState.content.promotions"></HomeFeatured>
  <div class="max-w-7xl mx-auto px-4 sm:px-6">
    <ListPanel title="Upcoming Events" allLink="/coming-soon" :panels="contentState.content.comingSoon"></ListPanel>
    <ListPanel title="Latest Videos" allLink="/latest-videos" :panels="contentState.content.latest"></ListPanel>
  </div>
  <SectionSupport></SectionSupport>
  <div id="volunteer" class="max-w-7xl mx-auto px-4 sm:px-6">
    <div class="mt-2 bg-white p-2 rounded-lg">
      <a href="/volunteer">
        <img src="/img/banners/volunteer-psc25.jpg" class="w-full rounded-lg" />
      </a>
    </div>
  </div>
  <div class="max-w-7xl mx-auto px-4 sm:px-6">
    <ListPanel title="Recent Events" allLink="/events" :panels="contentState.content.events"></ListPanel>
    <ListPanel title="Schools" allLink="/schools"  :panels="contentState.content.schools"></ListPanel>
    <ListPanel title="Conferences" allLink="/conferences" :panels="contentState.content.conferences"></ListPanel>
    <ListPanel title="Audio" allLink="/audio" :panels="contentState.content.audio"></ListPanel>
    <ListPanel title="YouTube" allLink="https://www.youtube.com/prayerstormtv" externalLink="true" :panels="contentState.content.youtubeVideos"></ListPanel>
  </div>
  <div id="mailing-list" class="max-w-7xl mx-auto pt-10 px-4 sm:px-6">
    <div class="flex justify-between">
      <h2 class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">Mailing List</h2>
    </div>
    <div class="mt-2 bg-white p-2 rounded-lg">
      <a href="/mailing-list">
        <div class="relative bg-mailing-list-img cursor-pointer pb-32 bg-center rounded-lg">
          <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
            &nbsp;
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="max-w-7xl mx-auto pt-10 px-4 sm:px-6">
    <div class="flex justify-between">
      <h2 class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">Membership</h2>
    </div>
    <div class="pt-2">
      <GoldStats></GoldStats>
    </div>
  </div>
  <div id="membership-upgrade">
    <MembershipList class="mt-20"></MembershipList>
  </div>
</main>
</template>

<script>
import { onMounted } from 'vue'
import { useContent } from '@/composables/useContent'
import MembershipList from '@/components/MembershipList.vue'
import ListPanel from '@/components/ListPanel.vue'
import LiveNow from '@/components/LiveNow.vue'
// import HomeVideo from '@/components/HomeVideo.vue'
import GoldStats from '@/components/GoldStats.vue'
import HomeFeatured from '@/components/HomeFeatured.vue'
import SectionSupport from '@/components/SectionSupport.vue'
import store from '@/store'

export default {
  name: 'Home',
  components: {
    MembershipList,
    ListPanel,
    LiveNow,
    // HomeVideo,
    GoldStats,
    HomeFeatured,
    SectionSupport
  },
  setup () {
    const x = Math.floor(Math.random() * 3) + 2
    const imgSrc = '/img/banners/ps-banner-' + x + '.jpg'
    const { contentState, searchContent } = useContent()

    onMounted(() => {
      searchContent()

      if (!window.psInit) {
        window.psInit = true
        store.commit('global/setLoadingModal', {
          open: true,
          style: {
            backgroundColor: 'rgba(42, 131, 161, 1)'
          }
        })

        setTimeout(() => {
          store.commit('global/setLoadingModal', {
            open: false
          })
        }, 2000)
      }
    })

    return {
      imgSrc,
      contentState
    }
  }
}
</script>

<style scoped>
.bg-mailing-list-img {
  background-image: url('/img/banners/mailing-list-bg.jpg');
  background-size: cover;
  background-position: center;
  padding-bottom: 28%;
}
@media only screen and (min-width: 768px) {
  .bg-mailing-list-img {
    padding-bottom: 18%;
  }
}
@media only screen and (min-width: 1024px) {
  .bg-mailing-list-img {
    padding-bottom: 14%;
  }
}
</style>
